import * as React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import SomeWorks from "../components/SomeWorks"
import ContactForm from "../components/ContactForm"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function IndexPage(props) {

  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('home')} />

      <SomeWorks images={props.data.someworks.edges} />

      <ContactForm />

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    someworks: allFile(
      filter: {
        relativeDirectory: {eq: "someworks"}, 
        extension: {regex: "/(jpg)|(png)|(jpeg)/"}
      }
      sort: {fields: base, order: ASC}
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid( maxWidth: 300, maxHeight: 200 ) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`